import React from "react";
import { Fade } from "react-reveal";

import buyIcon from "../../images/illustrations/buy.png";
import PrimaryButton from "../buttons/PrimaryButton/index";
import Select from "../Select/index";

export default function BuyAndTradeSection() {
  const formHandler = (e) => {
    e.preventDefault();
  };

  const inputChangeHandler = (e) => {
    console.log(e.target.value);
  };

  return (
    <section className="container py-32 mx-auto">
      <div className="grid gap-10 md:grid-cols-2">
        <div className="flex items-center">
          <div className="max-w-xl">
            <h2 className="mb-6 text-5xl font-bold leading-normal">
            For Web3 Citizens, <br /> Not Tourists
            </h2>
            <p className="mb-6 text-white text-[18px] font-sans">
            All your tokens, DeFi positions, NFT collections, <br />
            and transaction history across all major networks. <br />
            All in human-readable language. From a portfolio tracking,  <br />
dive into the details of anything you own. 
            </p>

            {/* <form onSubmit={formHandler}>
              <div className="flex justify-between gap-4 mb-6 md:gap-6">
                <div className="flex items-center px-4 py-3 border border-primary rounded-2xl md:py-4 md:px-6">
                  <div className="pr-4 border-r border-primary md:pr-6">
                    <small className="text-primary">Amount</small>
                  </div>
                  <input
                    type="text"
                    value="5,000"
                    onChange={inputChangeHandler}
                    className="w-full text-center text-white bg-black rounded-md outline-none h-7"
                  />
                </div>
                <Select value="USD" />
              </div>

              <div className="flex justify-between gap-4 mb-6 md:gap-6">
                <div className="flex items-center w-full px-4 py-3 border border-primary rounded-2xl md:py-4 md:px-6">
                  <div className="pr-4 border-r border-primary md:pr-6">
                    <small className="text-primary">Amount</small>
                  </div>
                  <input
                    type="text"
                    value="0.10901"
                    onChange={inputChangeHandler}
                    className="w-full text-center text-white bg-black rounded-md outline-none h-7"
                  />
                </div>
                <Select value="BTC" />
              </div>
            </form> */}
<a href="/signin">
            <PrimaryButton className="w-full">Track Portfolio</PrimaryButton>
            </a>
          </div>
        </div>
        <div className="row-start-1 md:col-start-2">
          <Fade up>
            <img src={buyIcon} alt="" />
          </Fade>
        </div>
      </div>
    </section>
  );
}
