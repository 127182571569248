import classNames from "classnames";
import React, { useState } from "react";
import { BsChevronUp } from "react-icons/bs";

import faq from "../../images/illustrations/faq.png";
import { Fade } from "react-reveal";

const FaqItem = ({ open, title, children }) => {
  const [isOpen, setIsOpen] = useState(open ? false : false);

  const iconClass = classNames({
    "transition-all duration-300": true,
    "rotate-180": isOpen,
  });

  const contentClass = classNames({
    "text-gray transition-all duration-300 overflow-hidden": true,
    "h-full": isOpen,
    "h-0": !isOpen,
  });

  return (
    <div className="pb-3 mb-3 border-b border-[#e7e8e8]">
      <div
        className="flex justify-between py-3 cursor-pointer hover:text-primary"
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <BsChevronUp className={iconClass} />
      </div>
      <div className={contentClass}>
        <p className="select-none">{children}</p>
      </div>
    </div>
  );
};

export default function FaqSection() {
  return (
    <section className="container py-32 mx-auto">
      <div className="grid md:grid-cols-2">
        <div className="mb-4">
          <Fade up duration={1000}>
            <img src={faq} alt="FAQ" />
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="">
            <span className="text-primary">SUPPORT</span>
            <h2 className="mb-6 text-4xl font-bold leading-normal">
              Frequently asked questions
            </h2>
            <div className="my-6 text-white ">
              <FaqItem  open={true} title="Is Folio-Trader Wallet safe ??">
              Folio-Trader is a non-custodial wallet audited by professional security firms, 
              including Cube53, Secfault Security, Peckshield, and Trail of Bits. 
              All security audits are public.   Folio-Trader Wallet’s core code base is 
              open-sourced. A bug bounty with Immunefi is there to reward whitehat 
              hackers who might uncover any additional vulnerabilities. 
              So, it’s fair to say that   Folio-Trader Wallet is safe.
              </FaqItem>
              <FaqItem open={false} title="Can I create wallets for different chains in   Folio-Trader Wallet ??">
              A wallet created with   Folio-Trader Wallet automatically works with all supported networks that are compatible with Ethereum. 
              This includes Arbitrum, Binance Smart Chain, Polygon, Optimism, Fantom and more.
              </FaqItem>
              <FaqItem open={false} title="How does trading work with   Folio-Trader crypto wallet ?">
                Folio-Trader Wallet automatically sources the best cryptocurrency prices from across different 
              decentralized exchanges (DEXes) such as Uniswap, 1Inch, Balancer, or Sushiswap.
              This is done using DeFi SDK, the open-source library developed by   Folio-Trader to integrate 
              with leading DeFi protocols. In practice, when swapping tokens in   Folio-Trader Wallet, 
              you’ll get the best price that is currently available on various DEXes.
              </FaqItem>
              <FaqItem open={false} title="Does   Folio-Trader offer DeFi portfolio tracker and where to find it ?">
              Yes,   Folio-Trader Wallet offers a built-in DeFi portfolio tracker, which automatically 
              finds and tracks all your DeFi positions, debts, and rewards.
You can find all your DeFi portfolio by tapping the ‘Tokens’ 
section and then changing the layout to ‘By Platform’ to 
arrange your assets by protocol.   Folio-Trader integrated DeFi 
tracking for over 500 protocols so it’s very likely that 
your DeFi assets will be tracked.
              </FaqItem>
              <FaqItem open={false} title="What is the best free crypto portfolio tracker ?">
              The best crypto portfolio tracking depends on what exactly you need to track.

  Folio-Trader Wallet offers a free portfolio tracker for DeFi and NFTs: it automatically finds and tracks all your tokens. 
However, this only works for non-custodial wallets, and   Folio-Trader will not be able to show assets you own on 
centralized exchanges.
              </FaqItem>
              <FaqItem open={false} title="How do I track my DeFi coins ?">
                Folio-Trader Wallet automatically tracks all DeFi coins you own. 
              There is no need to add any token or coin manually, they will all show up in the 
              ‘Tokens’ section of your wallet. To see how DeFi tracking works with   Folio-Trader, 
              you can first connect your existing wallet and   Folio-Trader will automatically track DeFi coins.

                Folio-Trader crypto wallet tracks all major coins listed on Coingecko as well as any other 
              ERC-20 (for new assets you might see a warning and would need to double check the coin’s smart contract).
              </FaqItem>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
