import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

import SecondaryButton from "../buttons/SecondaryButton";
import creditCard from "../../images/illustrations/credit-card.png";
import { Fade } from "react-reveal";

export default function CreditCardSection() {
  return (
    <section className="container py-32 mx-auto">
      <div className="grid gap-6 md:grid-cols-2">
        <div className="px-4 mb-12 md:px-4">
          <Fade up>
            <img src={creditCard} alt="" />
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="max-w-md">
            <h2 className="mb-6 text-4xl font-bold leading-normal">
            One Wallet. Layers of Possibilites with  <br /> <span className="text-blue-gradient">Folio-Trader</span>{" "}
        
            </h2>
            <p className="text-white">
            Safely explore all of web3 with complete peace of mind.{" "}
              <br /> at application.
            </p>

            <ul className="my-6">
              <li className="mb-2">
                <BsCheckCircleFill className="inline mr-2 text-primary" />
                Multichain By Default
              </li>
              <li className="mb-2">
                <BsCheckCircleFill className="inline mr-2 text-primary" />
                The Best Deal on Every Trade
              </li>
              <li className="mb-2">
                <BsCheckCircleFill className="inline mr-2 text-primary" />
                Every Token, Staked Asset and NFT
              </li>
            </ul>
          <a href="/signin">
            <SecondaryButton>Join the waitlist</SecondaryButton>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
