import React from "react";
import { BsCheckCircleFill } from "react-icons/bs";

import banks from "../../images/illustrations/banks.png";
import { Fade } from "react-reveal";

export default function SecuritySection() {
  return (
    <section className="container py-32 mx-auto">
      <div className="grid md:grid-cols-2">
        <div>
          <Fade up>
            <img src={banks} alt="" />
          </Fade>
        </div>
        <div className="flex justify-center">
          <div className="max-w-xl">
            <h2 className="mb-6 text-4xl font-bold leading-normal">
              Industry-leading security from day one
            </h2>
            <ul className="my-6">
              <li className="mb-6">
                <span>
                  <BsCheckCircleFill className="inline mr-2 text-primary" />
                  Safety, security and compliance
                </span>
                <p className="text-lightgray">
                  Folio-Trader is a licensed New York trust company that undergoes
                  regular bank exams and is subject to the cybersecurity audits
                  conducted by the New York Department of Financial Services.
                  Learn more about our commitment to security.
                </p>
              </li>
              <li className="mb-6">
                <span>
                  <BsCheckCircleFill className="inline mr-2 text-primary" />
                  Hardware security keys
                </span>
                <p className="text-lightgray">
                  With Folio-Trader  you can secure your account with a hardware security
                  key via WebAuthn.
                </p>
              </li>
              <li className="mb-6">
                <span>
                  <BsCheckCircleFill className="inline mr-2 text-primary" />
                  SOC Certifications
                </span>
                <p className="text-lightgray">
                Folio-Trader  is SOC 1 Type 2 and SOC 2 Type 2 compliant. We are the
                  world’s first cryptocurrency exchange and custodian to
                  complete these exams.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}
