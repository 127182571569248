import React from "react";

import signupImage from "../../images/illustrations/signup.png";
import walletImage from "../../images/illustrations/wallet.png";
import okayImage from "../../images/illustrations/okay.png";
import arrow from "../../images/arrow.svg";
import { Fade } from "react-reveal";

export default function StepSection() {
  return (
    <section className="px-6">
      <div
        style={{ backgroundColor: "#2b0646" }}
        className="py-20 rounded-3xl"
      >
        <div className="container mx-auto text-center">
          <h2 className="mb-6 text-4xl font-bold leading-normal">
            Get started in just a few minutes
          </h2>
          <div className="justify-center gap-20 md:flex">
            <Fade up>
              <div className="relative px-4 text-center">
                <div className="relative">
                  <img
                    src={signupImage}
                    className="mx-auto mb-4 transition-all duration-300 hover:-translate-y-6 hover:scale-105"
                    alt=""
                  />
                  <img
                    src={arrow}
                    alt=""
                    className="absolute hidden md:block top-1/2 -right-36"
                  />
                </div>
                <h3 className="mb-4 text-2xl font-bold">Sign Up</h3>
                <p className="max-w-sm text-white">
                Set up your Folio-Trader wallet with our step-by-step guides and learn key concepts.
                </p>
              </div>
            </Fade>
            <Fade up delay={500}>
              <div className="relative px-4 text-center">
                <div className="relative">
                  <img
                    src={walletImage}
                    className="mx-auto mb-4 transition-all duration-300 hover:-translate-y-6 hover:scale-105"
                    alt=""
                  />
                  <img
                    src={arrow}
                    alt=""
                    className="absolute hidden md:block top-1/2 -right-36"
                  />
                </div>
                <h3 className="mb-4 text-2xl font-bold">Connect Account</h3>
                <p className="max-w-sm text-white">
                  Choose your preferred wallet provider such as Trust Wallet  or
                 Binance web3 to connect to afolio-Trader.
                </p>
              </div>
            </Fade>
            <Fade up delay={1000}>
              <div className="relative px-4 text-center">
                <img
                  src={okayImage}
                  className="mx-auto mb-4 transition-all duration-300 hover:-translate-y-6 hover:scale-105"
                  alt=""
                />
               <h3 className="mb-4 text-2xl font-bold">Tracker Folio</h3>
<p className="max-w-sm text-white">
  Sign up for your free  Folio-Trader account on web, iOS, or Android and easily 
  manage your crypto portfolio in one place. With our intuitive interface, 
  you can track your history accross all network, monitor market trends, and stay updated on
   your portfolio’s performance—all at your fingertips. Take control of your 
   crypto assets with Folio-Trader today!
</p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </section>
  );
}
