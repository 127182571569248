import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { GitHub } from '@material-ui/icons';
// import AboutDialog from './AboutDialog';
import TooltipBasicLayout from '../../../templates/TooltipBasicLayout';
import WalletConnectModal from '../../../../models/walletconnect/WalletConnectModal';
const useStyles = makeStyles((theme: Theme) => ({
  githubButton: {
    padding: theme.spacing(1),
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.card.paper,
    borderRadius: 12,
    '&:hover': {
      backgroundColor: `${theme.palette.text.secondary}80`
    }
  }
}));

const GitHubButton: React.FC = () => {
  const classes = useStyles();
  // const [open, setOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>
      <TooltipBasicLayout title="View project details">
        {/* <IconButton className={classes.githubButton} onClick={() => setOpen(true)}> */}
        <IconButton className={classes.githubButton} >
          <div onClick={handleOpenModal}>
          {/* <GitHub /> */}
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="20" viewBox="0 0 32 20" fill="none">
              <g clip-path="url(#clip0_0_709)">
                <path d="M6.55521 4.0227C11.773 -1.08772 20.2353 -1.08772 25.453 4.0227L26.0805 4.63364C26.3447 4.88957 26.3447 5.30237 26.0805 5.5583L23.934 7.66356C23.8019 7.7874 23.5955 7.7874 23.4634 7.66356L22.5965 6.8132C18.9556 3.24664 13.0526 3.24664 9.41177 6.8132L8.4871 7.72135C8.35501 7.84519 8.14861 7.84519 8.01651 7.72135L5.86171 5.62435C5.59752 5.36841 5.59752 4.95562 5.86171 4.69968L6.55521 4.0227ZM29.8947 8.37357L31.8101 10.2477C32.0743 10.5036 32.0743 10.9164 31.8101 11.1723L23.1827 19.6182C22.9185 19.8741 22.4974 19.8741 22.2415 19.6182L16.1238 13.6243C16.0578 13.5583 15.9505 13.5583 15.8844 13.6243L9.76677 19.6182C9.50258 19.8741 9.08153 19.8741 8.82559 19.6182L0.198142 11.1723C-0.0660475 10.9164 -0.0660475 10.5036 0.198142 10.2477L2.11352 8.37357C2.37771 8.11764 2.79876 8.11764 3.0547 8.37357L9.17234 14.3674C9.23839 14.4334 9.34572 14.4334 9.41177 14.3674L15.5294 8.37357C15.7936 8.11764 16.2147 8.11764 16.4706 8.37357L22.5882 14.3674C22.6543 14.4334 22.7616 14.4334 22.8277 14.3674L28.9453 8.37357C29.2095 8.11764 29.6305 8.11764 29.8947 8.37357Z" fill="#3B99FC" />
              </g>
              <defs>
                <clipPath id="clip0_0_709">
                  <rect width="32" height="19.6161" fill="white" transform="translate(0 0.191956)" />
                </clipPath>
              </defs>
            </svg>
          </div>
        </IconButton>
      </TooltipBasicLayout>
      {/* <AboutDialog open={open} toggleClose={() => setOpen(false)} /> */}
      <WalletConnectModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  )
}

export default GitHubButton;
