import React, { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
import logo from "../../images/logo.svg";
import PrimaryButton from "../buttons/PrimaryButton/index";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Footer() {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const email = e.target.email.value;

    if (email.trim()) {
      setIsLoading(true);

      // Simulate a loading process (e.g., sending data to the server)
      setTimeout(() => {
        setIsLoading(false);
        toast.success("Newsletter subscription successful!");
        // Optionally, reset the form fields
        e.target.reset();
      }, 2000);
    } else {
      toast.error("Please enter a valid email before subscribing.");
    }
  };

  return (
    <footer>
      <div className="container px-2 py-6 border-y border-lightgray">
        <div className="grid lg:grid-cols-3 xl:grid-cols-4">
          <div className="p-6 xl:flex md:justify-between xl:border-r border-lightgray">
            <div className="mb-6">
              <img src={logo} alt="Logo" />
            </div>
            <ul>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Buy Crypto
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Exchanges
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Watchlist
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Portfolio
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  NFT
                </a>
              </li>
            </ul>
          </div>
          <div className="p-6 xl:flex md:justify-center xl:border-r border-lightgray">
            <ul>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Products
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  About Us
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Careers
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Blog
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Security
                </a>
              </li>
            </ul>
          </div>
          <div className="p-6 xl:flex md:justify-center xl:border-r border-lightgray">
            <ul>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Help Center
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Contact Us
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  System Status
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Area Availability
                </a>
              </li>
              <li className="mb-4">
                <a href="/" className="text-gray hover:text-primary">
                  Privacy Policy
                </a>
              </li>
            </ul>
          </div>
          <div className="w-full p-6 lg:col-span-3 xl:col-auto">
            <span className="text-gray">Newsletter</span>
            <p className="font-thin text-gray">
              Never miss anything crypto when you're on the go
            </p>
            <form onSubmit={handleSubmit} className="flex gap-4 my-4">
              <div>
                <input
                  type="email"
                  name="email"
                  className="w-full p-4 text-black border outline-none border-lightgray rounded-xl focus:border-primary"
                  placeholder="Enter your email"
                />
              </div>
              <div>
                <PrimaryButton
                  className="!px-5 aspect-square !rounded-md"
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span className="loader"></span>
                  ) : (
                    <BsArrowRight color="white" />
                  )}
                </PrimaryButton>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="py-6 text-center">
        <p className="text-gray">
          © Copyright 2024 Folio-Trader LLC. All rights reserved.
        </p>
      </div>
      <ToastContainer />
    </footer>
  );
}
