import React from 'react';

import DropdownMenu from './DropdownMenu';

export default function Menus() {
  return (
    <>
      <div className="w-full">
        <a href="/" className='block py-2 hover:text-primary'>Cryptocurrency</a>
      </div>
      <div className="w-full">
        <a href="/signin" className='block py-2 hover:text-primary'>FAA</a>
      </div>
      <div className="w-full">
        <a href="/signin" className='block py-2 hover:text-primary'>Watchlist</a>
      </div>
      <div className="w-full">
        <a href="/signin" className='block py-2 hover:text-primary'>NFT</a>
      </div>
      <div className="w-full">
        <a href="/signin" className='block py-2 hover:text-primary'> Cryptocurrency</a>
      </div>
      <DropdownMenu>
        <div className="w-full">
          <a href="/signin" className='block py-2 hover:text-primary'>Portofolios</a>
        </div>
        <div className="w-full">
          <a href="/signin" className='block py-2 hover:text-primary'>Portofoliosx</a>
        </div>
      </DropdownMenu>
    </>
  );
}
