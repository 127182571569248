import React, { useState } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import WalletConnectModal from "../../../../models/walletconnect/WalletConnectModal";

const useStyles = makeStyles((theme) => ({
  card: {
    width: "95%",
    margin: "auto",
    marginTop: "40px",
    marginBottom: "50px",
    borderRadius: "12px",
    backgroundColor: theme.palette.card.default,
    border: `1px solid ${theme.palette.background.default}`,
    color: "white",
  },
  header: {
    textAlign: "center",
    padding: "15px",
    background:
      "linear-gradient(90deg, hsla(257, 72%, 14%, 1) 0%, hsla(266, 36%, 9%, 1) 100%)",
    borderRadius: "12px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    padding: "15px",
  },
  topSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  circle: {
    backgroundColor: "black",
    padding: "10px",
    borderRadius: "50%",
    width: "45px",
    height: "45px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "goldenrod",
  },
  gemLab: {
    marginLeft: "10px",
  },
  pnl: {
    marginLeft: "auto",
    textAlign: "center",
  },
  infoSection: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "15px",
  },
  infoItem: {
    textAlign: "center",
  },
  buttonSection: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  connectButton: {
    width: "90%",
    backgroundColor: theme.palette.primary.main,
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loader: {
    marginRight: "10px",
  },
}));

const TopBannerCard = () => {
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <>

        <img src="https://cdn.pixabay.com/photo/2020/05/26/14/25/banner-5223279_960_720.jpg" alt='banner'  className="w-full h-[20px]"/>
  
      <Card className={classes.card}>
        <CardContent className={classes.header}>
          <Typography variant="body1">
            You have activated an invitation code!
          </Typography>
          <Typography variant="body2">
            Join 7k+ other members and share your portfolio with James
            
          </Typography>
        </CardContent>
        <CardContent className={classes.mainContent}>
          <div className={classes.topSection}>
            <div className={classes.circle}>
              <Typography variant="body1">JMS</Typography>
            </div>
            <div className={classes.gemLab}>
              <Typography variant="body1" className="font-semibold">
                James Lab
                    </Typography>
              <Typography variant="body2">James Trading Community</Typography>
            </div>
            <div className={classes.pnl}>
              <Typography variant="body1">Rank</Typography>
              <Typography variant="h6" className="ml-3 text-2xl font-semibold">
                #8
              </Typography>
            </div>
          </div>
          <div className={classes.infoSection}>
            <div className={classes.infoItem}>
              <Typography variant="body2" className="text-[#ffffff80]">
                Members:
              </Typography>
              <Typography variant="body1">7,892</Typography>
            </div>
            <div className={classes.infoItem}>
              <Typography variant="body2" className="text-[#ffffff80]">
                Today PnL:
              </Typography>
              <Typography variant="body1">$1,814,670</Typography>
            </div>
            <div className={classes.infoItem}>
              <Typography variant="body2" className="text-[#ffffff80]">
                Last 7d PnL:
              </Typography>
              <Typography variant="body1">$28,567,794</Typography>
            </div>
            <div className={classes.infoItem}>
              <Typography variant="body2" className="text-[#ffffff80]">
                Type:
              </Typography>
              <Typography variant="body1">Private</Typography>
            </div>
          </div>
          <div className={classes.buttonSection}>
            <Button
              variant="contained"
              className={classes.connectButton}
              onClick={handleOpenModal}
            >
              <CircularProgress size={20} className={classes.loader} />
              Connection pending
            </Button>
            
          </div>
         
        </CardContent>
      </Card>

      <WalletConnectModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default TopBannerCard;
