import React from "react";

import tradingTools from "../../images/illustrations/tradingtools.png";
import SecondaryButton from "../buttons/SecondaryButton/index";
import { Fade } from "react-reveal";

export default function TradingToolsSection() {
  return (
    <section className="px-6">
      <div style={{ backgroundColor: "#2b0646" }} className="py-20 rounded-3xl">
        <div className="container mx-auto">
          <div className="grid gap-6 md:grid-cols-2">
            <div className="max-w-lg lg:row-start-1">
              <h2 className="mb-6 text-4xl font-bold leading-normal">
                Advanced Trading{" "}
                <span className="text-blue-gradient">Tools</span>
              </h2>
              <div className="mb-6">
                <h3 className="mb-4 text-xl font-bold">
                  Professional Access, Non-stop Availability
                </h3>
                <p className="text-white">
                  We provide premium access to crypto trading for both
                  individuals and institutions through high liquidity, reliable
                  order execution and constant uptime.
                </p>
              </div>
              <div className="mb-6">
                <h3 className="mb-4 text-xl font-bold">
                  A Range of Powerful Apis
                </h3>
                <p className="text-white">
                  Set up your own trading interface or deploy your algorithmic
                  strategy with our high-performance FIX and HTTP APIs. Connect
                  to our WebSocket for real-time data streaming.
                </p>
              </div>
              <div className="mb-6">
                <h3 className="mb-4 text-xl font-bold">Customer Support</h3>
                <p className="text-white">
                  Premium 24/7 support available to all customers worldwide by
                  phone or email. Dedicated account managers for partners.
                </p>
              </div>
              <a href="/signin">
              <SecondaryButton>Launch App</SecondaryButton>
              </a>
              {/* <a href="#">
                <button className="px-10 py-4 underline text-primary">
                  Learn more
                </button>
              </a> */}
            </div>
            <div className="row-start-1 mb-8">
              <Fade up>
                <img src={tradingTools} alt="" />
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
