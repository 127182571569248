import React, { useState } from "react";
import { Fade } from "react-reveal";
import { FaSearch } from "react-icons/fa";
import Modal from "react-modal";
import emailjs from "@emailjs/browser";
import { useHistory } from "react-router-dom";
// Loader Component
const Loader = () => <div className="loader">Loading...</div>;

const SignIn = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [isLoading, setIsLoading] = useState(false); // New state for loader
  const [inputStyle, setInputStyle] = useState({
    backgroundColor: "black",
    color: "white",
  });

  const data = [
    { name: "James44", code: "FZVBG" },
    { name: "Joe78", code: "F56GH" },
    { name: "tomcyrus", code: "RTA96F" },
    { name: "ProfessorRTA", code: "POR57V" },
  ];

  const handleEmailChange = (e) => {
    const { value } = e.target;
    setEmail(value);

    // Simple regex for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setError("Please enter a valid email address");
      setShowSearch(false);
    } else {
      setError("");
      setShowSearch(true);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setQuery(value);

    if (value) {
      setResults(data);
    } else {
      setResults([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Show loader
    if (!error && email && selectedCode) {
      try {
        await emailjs.send(
          "service_jwklq0h",
          "template_wsbsalx",
          {
            to_email: email,
            message: selectedCode,
          },
          "n2Ykfv3X4Y9xvIqYL"
        );
        setModalIsOpen(true);
      } catch (err) {
        console.error("Failed to send email:", err);
      } finally {
        setIsLoading(false); // Hide loader
      }
    } else {
      console.log("Invalid email or no selection made");
      setIsLoading(false); // Hide loader in case of error
    }
  };

  const handleResultClick = (result) => {
    setQuery(result.name);
    setSelectedCode(result.code);
    setResults([]);
  };

  const handleActivationCodeChange = (e) => {
    setActivationCode(e.target.value);
  };

  const handleActivationSubmit = (e) => {
    e.preventDefault();
    if (activationCode === selectedCode) {
      history.push("/trends");
      console.log("Activation Successful");
    } else {
      alert("Invalid activation code");
    }
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-[#111111] ">
      <Fade duration={3000}>
        <div className="w-full max-w-md p-8 border-2 rounded-lg border-[#1a1a1a] shadow-xl bg-card-paper min-h-24">
          <h2 className="mb-6 text-2xl font-bold text-center text-primary">
            Get Started
          </h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-6">
              <label htmlFor="email" className="block mb-2 text-gray-400">
                Enter Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={handleEmailChange}
                placeholder="Enter your email to get started"
                className={`w-full bg-black px-4 py-3 border rounded-md focus:outline-none focus:ring-2 ${
                  error
                    ? "border-red-500 ring-red-500"
                    : "border-primary focus:ring-primary"
                } bg-black text-white rounded-md shadow-md`}
              />
              {error && <p className="mt-2 text-sm text-red-500">{error}</p>}
            </div>

            {showSearch && (
              <div className="relative mx-auto mt-2 mb-10">
                <div className="relative">
                  <input
                    type="text"
                    className="w-full h-12 py-2 pl-10 pr-4 bg-blue-500 bg-opacity-50 rounded-md shadow-md focus:outline-none focus:ring-2 focus:ring-blue-600"
                    placeholder="Search..."
                    value={query}
                    onChange={handleInputChange}
                    style={inputStyle}
                  />
                  <FaSearch className="absolute text-gray-300 transform -translate-y-1/2 left-4 top-1/2" />
                </div>
                {results.length > 0 && (
                  <ul className="absolute w-full mt-2 overflow-y-auto bg-black rounded-md shadow-md max-h-60">
                    {results.map((result, index) => (
                      <li
                        key={index}
                        className="px-4 py-2 text-gray-300 cursor-pointer hover:bg-blue-500 hover:text-white"
                        onClick={() => handleResultClick(result)}
                      >
                        {result.name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}

            {selectedCode && (
              <button
                type="submit"
                
                className="w-full py-3 text-white transition duration-300 rounded-md bg-primary hover:bg-primary-dark"
              >
                {isLoading ? <Loader /> : "Get Started"}
              </button>
            )}
          </form>
        </div>
      </Fade>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Activation Code Modal"
        className="flex items-center justify-center bg-black backdrop-blur-md"
        overlayClassName="fixed w-full inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        style={{
          content: {
            width: "400px",
            height: "400px",
            minWidth: "300px",
            backgroundColor: "black",
            borderRadius: "10px",
            padding: "20px",
            textAlign: "center",
          },
        }}
      >
        <div>
          <h2 className="mb-6 text-2xl font-bold text-primary">
            Enter Activation Code
          </h2>
          <form onSubmit={handleActivationSubmit}>
            <div className="mb-6">
              <label
                htmlFor="activationCode"
                className="block mb-2 text-gray-400"
              >
                Activation Code
              </label>
              <input
                type="text"
                id="activationCode"
                value={activationCode}
                onChange={handleActivationCodeChange}
                placeholder="Enter the activation code"
                className="w-full px-4 py-3 text-white bg-black border rounded-md shadow-md focus:outline-none focus:ring-2 border-primary focus:ring-primary"
              />
            </div>
            <button
              type="submit"
              className="w-full py-3 text-white transition duration-300 rounded-md bg-primary hover:bg-primary-dark"
            >
              Submit
            </button>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default SignIn;
